<!--
 * @Author: lbh
 * @Date: 2021-04-23 18:23:40
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-27 15:29:38
 * @Description: 客戶案例
-->
<template>
  <div
    class="case t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="case"
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        :title="isRicepon ? $t('case.bigTitle') : '我們的客戶'"
        :type="1"
        :image="getImgPath('subbannercase.jpg')"
      />
      <div v-if="isRicepon">
        <!-- 熱門案例 -->
        <div class="big-title">{{$t('case.titleA')}}</div>
        <div
          class="items"
          :class="window.type != '1000' ? 'items_1' : ''"
        >
          <div
            class="item"
            v-for="(item,index) in caseArr"
            :key="index"
            :class="`item_${index}`"
            @mouseover.stop="itemOver(`.item_${index}`)"
            @mouseout.stop="itemOut(`.item_${index}`)"
          >
            <img :src="item.img" />
            <div class="mask"></div>
            <div
              class="content-box"
              @mouseout.stop
              @mouseover.stop
            >
              <div
                class="title"
                v-text="item.title"
              ></div>
              <div
                class="content"
                v-text="item.content"
              ></div>
            </div>
          </div>
        </div>
        <!-- 輪播 -->
        <div class="big-title">{{$t('case.titleB')}}</div>
        <div
          class="swiper-container"
          :class="window.type == '600' ? 'swiper-container_1' : ''"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item,index) in swiperArr"
              :key="index"
            >
              <img :src="item" />
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <div
        v-else
        class="img-box"
      >
        <img
          v-for="(item,index) in swiperArr"
          :key="index"
          :src="item"
        />
      </div>
    </div>
    <selfFooter :type="window.type" />
    <self-contact v-if="!isRicepon" />
  </div>
</template>
<script>
import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import util from "@/js/utils/util";
import jquery from 'jquery'
import Swiper from '@/store/swiper/swiper.js'
import '@/store/swiper/swiper.css';
export default {
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0,
      },
      caseArr: [],
      swiperArr: [],
      swiper: null,
      viewIndex: 0,
      isRicepon: true
    }
  },
  mounted () {
    util.setTitle(`${this.$t('case.bigTitle')} - ${this.$t('companyName')}`);
    let isRicepon = util.isRicepon();
    this.isRicepon = isRicepon;
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    let caseArr = [
      { title: this.$t('case.labelA'), content: this.$t('case.descA'), img: util.getImgPath('beppu_shop-500x403.jpg') },
      { title: this.$t('case.labelB'), content: this.$t('case.descA'), img: util.getImgPath('001-1-500x403.png') },
      { title: this.$t('case.labelC'), content: this.$t('case.descB'), img: util.getImgPath('WCC_shop-500x403.jpg') }
    ]
    this.caseArr = caseArr;
    let swiperArr = [
      util.getImgPath('logo00.jpg'),
      util.getImgPath('logo02.jpg'),
      util.getImgPath('logo03.jpg'),
      util.getImgPath('logo04.jpg')
    ];
    this.swiperArr = swiperArr;


    let W = util.W(), w = W.w, h = W.h;
    if (w > 700) {
      this.initSwiper(1.5);
    }


  },
  created () {

  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      this.window.backH = parseInt(w / 2.5);
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 0.7;
        if (this.isRicepon)
          this.initSwiper(1.1);
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 0.7;
        if (this.isRicepon)
          this.initSwiper(1.5);
      } else {
        this.window.type = '1000';
        this.window.class_ = '';
        if (this.isRicepon)
          this.initSwiper(1.5);
      }
    },
    initSwiper (view = 1.5) {
      if (view == this.viewIndex)
        return;
      this.viewIndex = view;
      setTimeout(() => {
        this.swiper = new Swiper({
          el: '.swiper-container',
          initialSlide: 2,
          loop: true, // 循环模式选项
          spaceBetween: 50,
          slidesPerView: view,
          centeredSlides: true,
          slideToClickedSlide: true,
          grabCursor: true,
          keyboard: {
            enabled: true,
          },
          autoplay: {
            delay: 3000
          },
          pagination: {
            el: '.swiper-pagination',
          },
        });
      }, 500);
    },
    getImgPath (t) {
      return util.getImgPath(t);
    },
    itemOver (t) {
      jquery(`${t} .content-box`).stop();
      jquery(`${t} .content-box`).animate({
        bottom: 0,
        opacity: 1
      }, {
        speed: 50,
      })
      jquery(`${t} .mask`).stop();
      jquery(`${t} .mask`).animate({
        opacity: 0.3,
      })
    },
    itemOut (t) {
      jquery(`${t} .content-box`).stop();
      jquery(`${t} .content-box`).animate({
        bottom: -90,
        opacity: 0
      }, {
        speed: 50,
        callback: () => {
        }
      })
      jquery(`${t} .mask`).stop();
      jquery(`${t} .mask`).animate({
        opacity: 0
      })
    },

  }
}
</script>
<style lang="less" scoped>
.swiper-container {
  width: 100%;
  padding: 30px 0;
  margin: 50px auto;
}
.swiper-container_1 {
  margin-top: 0 !important;
}
.swiper-slide {
  background: #f1f1f1;
  color: #000;
  text-align: center;
  box-shadow: 1px 0px 20px 6px #ccc;
  img {
    width: 100%;
  }
}
.case {
  .big-title {
    font-size: 40px;
    font-weight: 400;
    padding: 10px 6%;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      bottom: 10px;
      right: 6%;
      background-color: #ddd;
      left: 50%;
      height: 1px;
    }
  }
  .items_1 {
    .item {
      width: 100% !important;
    }
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 6%;
    margin-bottom: 90px;
    .item {
      width: calc(33% - 20px);
      margin: 20px 10px;
      min-width: 300px;
      position: relative;
      &:hover {
        & .mask {
          display: block;
        }
      }
      img {
        width: 100%;
      }
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        opacity: 0;
        display: none;
      }
      .content-box {
        position: absolute;
        width: calc(100% - 50px);
        left: 0;
        bottom: -90px;
        opacity: 0;
        color: #fff;
        padding: 30px 25px;
        .title {
          font-size: 30px;
          font-weight: 400;
        }
      }
    }
  }
  .img-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      max-width: 100%;
    }
  }
}
</style>
